:root {
    --primary: #faeba3;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 14px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #192b3d;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #faeba3;
    padding: 8px 20px;
    border: 3px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #faeba3;
    color: #192b3d;
    transition: 250ms;
  }

  .btn--primary:hover {
    background-color: transparent;
    color: #faeba3;
    padding: 8px 20px;
    border: 3px solid var(--primary);
    transition: all 0.3s ease-out;
    padding: 12px 26px;
    /* font-size: 20px; */
  }