.authWrapper {
    display: block;
    width: 100%;
    max-width: 40.0rem;
    border: 1px solid rgba(25, 43, 61,1);
    margin: 4rem auto 6rem;

    .wrap {
        padding: 10px 20px;

        h2 {
            font-size: 2.2rem;
            line-height: 1;
            font-weight: 400;
            text-transform: uppercase;
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 auto 3rem;
            padding: 0;
        }
    }
}